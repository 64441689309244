import { combineReducers } from '@reduxjs/toolkit';
import { createApiThunk } from '../../createApiThunk';
import { createApiSlice } from '../../createApiSlice';
import { fymApi } from '../../../components/fymApi/FymAPI';

export const adminLicensesList = createApiThunk('adminLicensesList', fymApi.adminLicensesList.bind(fymApi));
const adminLicensesListSlice = createApiSlice('licensesList', adminLicensesList);

export const adminLicensesDetails = createApiThunk('adminLicensesDetails', fymApi.adminLicensesDetails.bind(fymApi));
const adminLicensesDetailsSlice = createApiSlice('adminLicensesDetails', adminLicensesDetails);

// Send email
export const adminLicensesSendLicenseEmail = createApiThunk(
  'adminLicensesSendLicenseEmail',
  fymApi.adminLicensesSendLicenseEmail.bind(fymApi)
);
const adminLicensesSendLicenseEmailSlice = createApiSlice(
  'adminLicensesSendLicenseEmail',
  adminLicensesSendLicenseEmail
);

// Change email
export const adminLicensesChangeEmail = createApiThunk(
  'adminLicensesChangeEmail',
  fymApi.adminLicensesChangeEmail.bind(fymApi)
);
const adminLicensesChangeEmailSlice = createApiSlice('adminLicensesChangeEmail', adminLicensesChangeEmail);

// Extend Subscription
export const adminLicensesExtendSubscription = createApiThunk(
  'adminLicensesExtendSubscription',
  fymApi.adminLicensesExtendSubscription.bind(fymApi)
);
const adminLicensesExtendSubscriptionSlice = createApiSlice(
  'adminLicensesExtendSubscription',
  adminLicensesExtendSubscription
);

// GDRP slices
export const adminLicensesRemovePersonalData = createApiThunk(
  'adminLicensesRemovePersonalData',
  fymApi.adminLicensesRemovePersonalData.bind(fymApi)
);

const adminLicensesRemovePersonalDataSlice = createApiSlice(
  'adminLicensesRemovePersonalData',
  adminLicensesRemovePersonalData
);

// Logout devices
export const adminLicensesLogoutDevices = createApiThunk(
  'adminLicensesLogoutDevices',
  fymApi.adminLicensesLogoutDevices.bind(fymApi)
);

const adminLicensesLogoutDevicesSlice = createApiSlice('adminLicensesLogoutDevices', adminLicensesLogoutDevices);

// Switch Realm partition
export const adminLicensesSwitchRealmPartition = createApiThunk(
  'adminLicensesSwitchRealmPartition',
  fymApi.adminLicensesSwitchRealmPartition.bind(fymApi)
);

const adminLicensesSwitchRealmPartitionSlice = createApiSlice(
  'adminLicensesSwitchRealmPartition',
  adminLicensesSwitchRealmPartition
);

export const adminLicensesReducer = combineReducers({
  licensesList: adminLicensesListSlice.reducer,
  licensesDetails: adminLicensesDetailsSlice.reducer,
  licensesSendLicenseEmail: adminLicensesSendLicenseEmailSlice.reducer,
  licensesRemovePersonalData: adminLicensesRemovePersonalDataSlice.reducer,
  licensesChangeEmail: adminLicensesChangeEmailSlice.reducer,
  licensesExtendSubscription: adminLicensesExtendSubscriptionSlice.reducer,
  licensesLogoutDevices: adminLicensesLogoutDevicesSlice.reducer,
  licensesSwitchRealmPartition: adminLicensesSwitchRealmPartitionSlice.reducer
});
